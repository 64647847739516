<template>
  <div id="PaymentModule" class="ReferencePaymentMain">
    <nav>
      <v-app-bar color="white" flat app>
        <v-img max-width="90px" src="../../public/faviconrzhd.png" pl="0"></v-img>
        <v-toolbar-title class="ml-3">
          <div class="Header">Дирекция железнодорожных вокзалов —</div>
          <div class="text-none mr-3 font-family-RussianRail">филиал ОАО «РЖД»</div>
        </v-toolbar-title>
      </v-app-bar>
    </nav>
    <br />
    <br />
    <br />
    <br />

    <v-card class="ma-auto" pa="6" max-width="40%">
      <div v-if="this.isConfirmLoading">
        <v-card-title> Проверка платежа, пожалуйста подождите... </v-card-title>
      </div>
      <div v-else-if="this.isConfirmError">
        <v-card-title> Не удалось проверить платёж, попробуйте ещё раз позже </v-card-title>
      </div>
      <div v-else-if="this.isConfirmComplete">
        <v-card-title> Заказ № {{ this.confirmOrderNumber }} </v-card-title>
        <v-card-text>
          Оплата заказа произведена успешно <br />
          Дата заказа: {{ getOrderDate }} <br />
        </v-card-text>
        <div v-if="confirmResult !== true">
          <v-card-text> Оплата заказа не выполнена </v-card-text>
        </div>
      </div>
      <v-card-actions class="align-items-center justify-space-around flex-wrap">
        <router-link to="/">Вернуться на главную</router-link>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  computed: {
    getOrderDate() {
      return moment(this.confirmOrderDate).format('DD/MM/YYYY HH:mm');
    },
    ...mapGetters('order', ['isConfirmLoading']),
    ...mapGetters('order', ['isConfirmError']),
    ...mapGetters('order', ['isConfirmComplete']),
    ...mapGetters('order', ['confirmResult']),
    ...mapGetters('order', ['confirmMessage']),
    ...mapGetters('order', ['confirmOrderNumber']),
    ...mapGetters('order', ['confirmOrderDate']),
    ...mapGetters('order', ['confirmReferenceName'])
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    ...mapActions('order', ['confirmPayment'])
  },
  mounted() {
    this.confirmPayment({ token: this.$route.query.token });
  },
  beforeDestroy() {
    // localStorage.setItem('lastOrderNum', this.confirmOrderNumber);
    localStorage.removeItem('takeout');
  }
};
</script>
<style>
.ReferencePaymentMain {
  font-weight: 400;
  font-family: RussianRail;
  background-color: rgba(57, 74, 88, 0.2);
  height: 100%;
}
</style>
